@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #9e9ea4;
  font-family: 'Poppins', sans-serif;
}

.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon, .icon_header {
  margin-right: 5px;
}

.close_icon {
  color: red;
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar header header header"
    "sidebar main main main"
    "sidebar main main main"
    "sidebar footer footer footer";
  height: 100vh;
  transition: grid-template-columns 0.4s ease; /* Ensure smooth transition */
}

:root {
  --sidebar-width-open: 260px;
  --sidebar-width-closed: 56px; /* Assuming 14rem is 56px */
}

.grid-container.sidebar-open {
  --sidebar-width: var(--sidebar-width-open);
}

.grid-container.sidebar-closed {
  --sidebar-width: var(--sidebar-width-closed);
}

.footer {
  bottom: 0;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  z-index: 10;
  background-color: #2e2e2e;
}

.footer h4 {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

/* Header  */
.header {
  grid-area: header;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
}

.header-landing {
  height:20vh;
  padding: 20px 80px;
}

.menu-icon {
  display: none;
}
/* End Header  */

/* Sidebar  */
#sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #ffffff;
    overflow-y: auto;
    /* border-radius: 0 20px 20px 0; */
    transition: width 0.3s ease;
    -webkit-transition: all 0.5s;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
}
.sidebar-title {
  display: flex;
  justify-content: space-center;
  align-items: center;
  padding: 15px 15px 0px 20px;
  margin: 15px 0 30px 0;
  color: #263043;
}

.sidebar-title > span {
  display: none;
  cursor: pointer;
}

.sidebar-brand {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
}

.sidebar-list {
  padding: 0;
  list-style-type: none;
}

/* .sidebar-list-item {
  padding: 20px 20px 20px 20px;
  font-size: 18px;
} */

.sidebar-list-item:hover {
  background-color: ;
  cursor: pointer;
  color: #263043;
}

.sidebar-list-item.active  {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.sidebar-list-item > a {
  text-decoration: none;
  color: #263043;
}

.sidebar-responsive {
  display: inline !important;
  position: absolute; 
  z-index: 12 !important;
}

.icon {
  transition: transform 0.4s;
}

.icon.rotate-180 {
  transform: rotate(180deg);
}

/* End Sidebar  */


/* Main  */  
.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
  background-color: #f8f8f8;
}

.main-title {
  display: flex;
  justify-content: space-between;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0.1, 0.1, 0.1, 0.1);
  border: none;
}

.card-inner > .card_icon {
  font-size: 25px;
}

.loading-placeholder {
  animation: shine 1.5s infinite;
  background: linear-gradient(90deg, rgba(224, 224, 224, 0.4), rgba(240, 240, 240, 0.4), rgba(224, 224, 224, 0.4));
  background-size: 200% 100%;
  color: transparent;
  display: inline-block;
  padding: 0.5em;
  border-radius: 8px;
}

.apexcharts-legend {
  border: 1px solid #000; /* Border properties */
  padding: 10px; /* Add padding to create space between the legend items and the border */
  border-radius: 5px; /* Add border radius for rounded corners */
}

@keyframes marquee {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animation {
  animation: marquee 20s linear infinite;
}

@keyframes twinkling {
  0% {
    background-color: #DD5746;
  }
  50% {
    background-color: #9BCF53;
  }
  100% {
    background-color: #5BBCFF;
  }
}

/* Linechart.css */
.chart-container .canvasjs-chart-container .canvasjs-chart-axis-x text {
  font-weight: bold; /* Apply bold font weight to x-axis text */
}


.chart-container {
  opacity: 0;
  transition: opacity 60s ease-in-out;
}

.chart-container.show {
  opacity: 50;
}

.transition-height {
  transition: height 0.8s ease-in-out;
  overflow: hidden;
}


.twinkling-bg {
  animation: twinkling 4s infinite; /* Adjust the duration as needed */
}

.neon-text {
  color: #fff; /* Set text color to white */
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 5px rgba(255, 255, 255, 0.8), 0 0 5px rgba(255, 255, 255, 0.8); /* Add multiple text shadows to create a neon glow effect */
  animation: neon-animation 1s ease-in-out infinite alternate; /* Add animation for pulsating effect */
}

@keyframes neon-animation {
  from {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.8);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.8), 0 0 60px rgba(255, 255, 255, 0.8);
  }
}


@keyframes shine {
  to {
    background-position: 200% 100%;
  }
}

.canvasjs-chart-credit {
  color: #fff;
  font-size: 1px;
}

.apexcharts-legends {
  display: none;
}

.apexcharts-tooltip {
  color: #000;
}

.apexcharts-toolbar {
  display:none;
  height: 50px;
  color: #000;
}


/* End Main  */

/* Modal Pop Up*/

/* Medium <= 992px */
  
@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */
  
@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .header-left {
    display: none;
  }
}

.chat-container {
  height: 45vh;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  justify-content: center;
}

.chat {
  padding: 10px;
}

.chat-input {
  border: 1px solid #ccc;
}

.chat-input:focus {
  border: 1px solid #238FBA;
}

.chat-message {
  margin-bottom: 10px;
  padding: 8px 12px;
  max-width: 70%;
  color: #333;
}

.received {
  background-color: #f2f2f2;
  justify-content: flex-start;
  border-radius: 0 20px 20px 20px;
  margin-bottom:0;
}

.sent {
  background-color: #238FBA;
  color: white;
  justify-content: flex-end;
  border-radius: 20px 0 20px 20px;
  margin-bottom:0;
}

.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.section.visible {
  opacity: 1;
  transform: translateY(0);
}

.apexcharts-legend {
  border: none;
}

.swal2-container {
  z-index: 1500 !important;
}

.apexcharts-bar-series .apexcharts-plot-series {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

/* shimmer.css */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.shimmer-gold {
  background: linear-gradient(
    90deg,
    rgba(255, 215, 0, 0.8) 20%,
    rgba(255, 215, 0, 0.6) 50%,
    rgba(255, 215, 0, 0.8) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 5s infinite;
}

.shimmer-silver {
  background: linear-gradient(
    90deg,
    rgba(	169,169,169, 0.8) 20%,
    rgba(	169,169,169, 0.6) 50%,
    rgba(	169,169,169, 0.8) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 5s infinite;
}